jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		navbar: {
			add: true,
			title: ""
		},
		extensions: [
			"position-left",
		]
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".main-content").fitVids({ ignore: "nofit" });
	}

	//  Scroll for more script
	$("#AnchorLink").click(function() {
		$('html,body').animate(
			{scrollTop: $("#Anchor").offset().top},
			800
		);
	});

	// Fix main navigation styles on iPad
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".navigation-menu").removeClass("hover-with-margin");
	}

	/**
	* ----------------------------------------------------------------------
	* Webflow: Interactions: Init
	*/
	var interactions = [];

	// If accessed by an ipad remove transitions from drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		interactions.push({slug: "dropdown-hover-2",name: "Dropdown Hover 2",value: {style: {},triggers: [{type: "hover",selector: ".vert-nav-child-wrapper",descend: true,stepsA: [{opacity: 1,height: "auto"}],stepsB: [{opacity: 0,height: "0px"}]}]}});
	}
	else {
		interactions.push({slug: "dropdown-hover-2",name: "Dropdown Hover 2",value: {style: {},triggers: [{type: "hover",selector: ".vert-nav-child-wrapper",descend: true,stepsA: [{opacity: 1,height: "auto",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}],stepsB: [{opacity: 0,height: "0px",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}]}]}});
	}

	interactions = interactions.concat([
		{slug: "search-dropdown",name: "Search Dropdown",value: {style: {},triggers: [{type: "click",stepsA: [{height: "auto",transition: "height 300ms ease 0"}],stepsB: [{height: "0px",transition: "height 300ms ease 0"}]}]}},
		{slug: "tagline-zoom-in",name: "Tagline Zoom In",value: {style: {opacity: 0,x: "0px",y: "-90px",z: "0px",scaleX: 0.5,scaleY: 0.5,scaleZ: 1},triggers: [{type: "load",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0, opacity 1000ms ease 0",x: "0px",y: "0px",z: "0px",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "tagline-zoom-in-2",name: "Tagline Zoom In 2",value: {style: {opacity: 0,x: "0px",y: "80px",z: "0px",scaleX: 0.5,scaleY: 0.5,scaleZ: 1},triggers: [{type: "load",preload: true,stepsA: [{wait: "500ms"},{wait: "200ms",opacity: 1,transition: "transform 1000ms ease 0, opacity 1000ms ease 0",x: "0px",y: "0px",z: "0px",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "tagline-zoom-in-3",name: "Tagline Zoom In 3",value: {style: {opacity: 0,x: "0px",y: "80px",z: "0px",scaleX: 0.5,scaleY: 0.5,scaleZ: 1},triggers: [{type: "load",preload: true,stepsA: [{wait: "1250ms"},{wait: "200ms",opacity: 1,transition: "transform 1000ms ease 0, opacity 1000ms ease 0",x: "0px",y: "0px",z: "0px",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "tagline-zoom-in-4",name: "Tagline Zoom In 4",value: {style: {opacity: 0,scaleX: 0.5,scaleY: 0.5,scaleZ: 1,x: "0px",y: "80px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "2000ms"},{wait: "200ms",opacity: 1,transition: "transform 1000ms ease 0, opacity 1000ms ease 0",x: "0px",y: "0px",z: "0px",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "scroll-down-appear",name: "Scroll Down Appear",value: {style: {opacity: 0,x: "-150px",y: "0px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "2300ms",transition: "opacity 200 ease 0"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []},{type: "scroll",offsetTop: "35%",stepsA: [],stepsB: [{opacity: 0,transition: "opacity 500ms ease 0"}]}]}},
		{slug: "scroll-down-arrow",name: "Scroll Down Arrow",value: {style: {},triggers: [{type: "load",preload: true,loopA: true,stepsA: [{transition: "transform 500ms ease 0",x: "0px",y: "-4px",z: "0px"},{transition: "transform 500ms ease 0",x: "0px",y: "4px",z: "0px"}],stepsB: []},{type: "scroll",offsetTop: "35%",stepsA: [],stepsB: [{opacity: 0,transition: "opacity 500ms ease 0"}]}]}},
		{slug: "scroll-for-more-text",name: "Scroll For More Text",value: {style: {},triggers: [{type: "scroll",offsetTop: "35%",stepsA: [],stepsB: [{opacity: 0,transition: "opacity 500ms ease 0"}]}]}},
		{slug: "dropdown-side-nav",name: "Dropdown Side Nav",value: {style: {},triggers: [{type: "hover",selector: ".vert-nav-child-wrapper",descend: true,stepsA: [{opacity: 1,height: "auto",transition: "height 200 ease 0, opacity 200 ease 0"}],stepsB: [{opacity: 0,height: "0px",transition: "opacity 200 ease 0, height 200 ease 0"}]}]}},
		{slug: "click-dropdown",name: "Click Dropdown",value: {style: {},triggers: [{type: "click",selector: ".vert-nav-child-wrapper",stepsA: [{display: "block",opacity: 1,height: "auto",transition: "transform 200 ease 0, height 300ms ease 0, opacity 300ms ease 0"}],stepsB: [{opacity: 0,height: "0px",transition: "height 200 ease 0, opacity 200 ease 0"}]}]}},
		{slug: "content-box-reveal",name: "Content Box Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetTop: "25%",offsetBot: "25%",stepsA: [{opacity: 1,transition: "opacity 500ms ease 0, transform 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "scroll-down-page",name: "scroll down page",value: {style: {},triggers: [{type: "click",selector: ".page-wrapper",preserve3d: true,stepsA: [{transition: "transform 500ms ease 0",x: "0px",y: "-25%",z: "0px"}],stepsB: []}]}},
		{slug: "google-translate-widget",name: "Google Translate Widget",value: {style: {display: "block"},triggers: []}}
	]);

	Webflow.require('ix').init(interactions);
});

// Google translate WCAG fixes
$(window).on("load",function() { // Do not change this line to document.ready
	$(".goog-te-combo").attr("id", "translate-select-custom");
	$(".goog-te-combo").before("<label class='sronly' for='translate-select-custom'>Select language to translate website</label>");
});
